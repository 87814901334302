/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { AnyAction, combineReducers } from '@reduxjs/toolkit'

import { InjectedReducersType } from '../utils/types/injector-typings'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return (state: any) => state
  }

  const appReducer = combineReducers({
    ...injectedReducers,
  })

  return (state: any, action: AnyAction) => {
    if (action.type === 'app/logout/response' || action.type === 'app/user/profile/delete-user/response') {
      return appReducer(
        {
          app: { isInitialized: true, isLoggedIn: false, userProfileIsComplete: false, showInnoRadarAnimation: true },
          notification: state.notification,
        },
        action
      )
    }
    return appReducer(state, action)
  }
}
