import slugify from 'slugify'
import { Location } from 'react-router-dom'
import { WindowLocation } from '@reach/router'
import { Projects } from '../types/directus'

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function slug(str: string, locale = 'vi') {
  return slugify(str, {
    replacement: '-', // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: false, // strip special characters except replacement, defaults to `false`
    locale: locale, // language code of the locale to use
  })
}

export function truncateOnWord(str: string, limit: number) {
  const trimAble =
    '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF'
  const reg = new RegExp(`(?=[${trimAble}])`)
  const words = str.split(reg)
  let count = 0
  const newStr = words
    .filter((word: string) => {
      count += word.length
      return count <= limit
    })
    .join('')
  return newStr < str ? `${newStr} ...` : str
}

export function truncateOnLength(str: string, limit: number) {
  if (str.length > limit) {
    return `${str.substring(0, Math.min(str.length, limit))} ...`
  }
  return str
}

export const isExternalURL = (url: string) => {
  if (typeof location !== 'undefined') {
    const domain = (url: string) => url.replace('http://', '').replace('https://', '').split('/')[0]
    return domain(location.href) !== domain(url)
  }
  return true
}

export const isAppAdmin = (id: string) => {
  return id === '93dd258c-faa7-49fb-9a28-e4be6aa61be2'
}

export const isPrivatePage = (currentUrl: string) => {
  return currentUrl.includes('/app/')
}

export const isWindowLocation = (location: Location | WindowLocation): location is WindowLocation => {
  return (<WindowLocation>location).href !== undefined
}

export const isValidProject = (project: Projects | undefined) => {
  return (
    (project &&
      project?.rings &&
      project.sectors &&
      project.blips &&
      project?.rings?.length > 0 &&
      project.sectors.length > 0 &&
      project.blips.length > 0) ||
    false
  )
}
