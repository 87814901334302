import { createSlice } from 'utils/@reduxjs/toolkit'
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors'
import { IInitialState } from './types'
import { saga } from './saga'
import { FormikProps } from 'formik'
import { IFormik } from '../index'
import { INotificationsPayload } from '../../../components/Notification/slice/types'

export const initialState: IInitialState = {
  isLoading: false,
  id: null,
  role: undefined,
  status: undefined,
  processing_status: undefined,
  email: '',
  avatar: null,
  first_name: '',
  last_name: '',
  position: '',
  institution: '',
  info: '',
  background: '',
  reason: '',
}

export interface IRequest {
  type: string
  payload: {
    formik: FormikProps<IFormik>
    notifications: INotificationsPayload
  }
}

export type TSetErrorsPayload = { general: null } | { general: string }

const slice = createSlice({
  name: 'app/user/profile',
  initialState,
  reducers: {
    request(state, { payload }: IRequest) {
      payload && (state.isLoading = true)
    },
    response(state) {
      state.isLoading = false
    },
    setPersonalData(
      state,
      {
        payload: {
          id,
          role,
          status,
          processing_status,
          email,
          avatar,
          first_name,
          last_name,
          position,
          institution,
          info,
          background,
          reason,
        },
      }
    ) {
      id && (state.id = id)
      role && (state.role = role)
      status && (state.status = status)
      processing_status && (state.processing_status = processing_status)
      email && (state.email = email)
      avatar && (state.avatar = avatar)
      first_name && (state.first_name = first_name)
      last_name && (state.last_name = last_name)
      position && (state.position = position)
      institution && (state.institution = institution)
      info && (state.info = info)
      background && (state.background = background)
      reason && (state.reason = reason)
    },
  },
})

export const { name: key, actions, reducer } = slice

export const useSlice = () => {
  useInjectReducer({ key, reducer })
  useInjectSaga({ key, saga })
  return { actions }
}
