import { all, call, put, takeLatest } from 'typed-redux-saga'
import { actions, IInit } from '.'
import { actions as userProfileActions } from 'components/App/pages/user-profile/slice'
import { getMe } from 'api'
import { checkUserProfileIsComplete, getToken, staticLogin } from 'api/api'

export function* initSaga({ payload }: IInit) {
  let userProfileIsComplete = false

  try {
    // If there is a static token in the URL, login with it.
    if (payload.token) {
      yield* call(staticLogin, payload.token)
    }

    // Refresh token if expired.
    const token = yield* call(getToken)
    if (!token) throw new Error('No token')
    const user = yield* getMe()

    // Check if user profile is complete.
    userProfileIsComplete = yield* call(checkUserProfileIsComplete)

    yield all([
      put(actions.response({ isInitialized: true, isLoggedIn: !!token, userProfileIsComplete })),
      put(userProfileActions.setPersonalData(user)),
    ])

    console.info('initialized')
  } catch (e) {
    yield put(actions.response({ isInitialized: true, isLoggedIn: false, userProfileIsComplete: false }))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* saga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.init.type, initSaga)
}
