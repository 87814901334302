import { call, put, select, takeLatest } from 'typed-redux-saga'
import { updateMe, uploadFile } from 'api'
import { actions, IRequest } from '.'
import { notificationsActions as notificationActions } from '../../../components/Notification/slice'
import { deleteFile } from '../../../../../api/api'
import { selectAvatar } from './selectors'

export function* requestSaga({ payload }: IRequest) {
  const { formik, notifications } = payload
  try {
    const file = formik.values.file

    if (!file) {
      throw new Error('No file')
    }

    const { id } = yield* uploadFile({ file })

    try {
      const avatar = yield* select(selectAvatar)

      if (!avatar) {
        throw new Error('No avatar')
      }

      yield* call(deleteFile, { fileId: avatar })
    } catch (e) {}

    yield call(updateMe, {
      ...payload.formik.values,
      avatar: id,
    })
    const { success } = notifications
    yield put(notificationActions.set(success))
  } catch (e) {
    const { error } = notifications
    yield put(notificationActions.set({ ...error, type: 'error' }))
  } finally {
    yield put(actions.response())
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* saga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.request.type, requestSaga)
}
