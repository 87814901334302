import { createSelector } from '@reduxjs/toolkit'

import { initialState } from '.'
import { RootState } from 'types'

const selectDomain = (state: RootState) => (typeof state !== 'undefined' ? state.app || initialState : initialState)

export const selectIsInitialized = createSelector([selectDomain], (state) => state.isInitialized)

export const selectIsLoggedIn = createSelector([selectDomain], (state) => state.isLoggedIn)

export const selectUserProfileIsComplete = createSelector([selectDomain], (state) => state.userProfileIsComplete)

export const selectShowInnoRadarAnimation = createSelector([selectDomain], (state) => state.showInnoRadarAnimation)
