import { call, delay, put } from 'typed-redux-saga'
import * as Api from './api'
import { actions as userProfileActions } from 'components/App/pages/user-profile/slice'
import {
  IResetPassword,
  IResetPasswordWithToken,
  IUpdateUser,
  IUserLogin,
  IUserRegistration,
  ProjectPermissions,
} from './types'
import { FormikProps } from 'formik'
import { IFormik as IFormikCreateProject } from 'components/App/pages/project-create'
import { IFormik as IFormikSectorCreate } from 'components/App/pages/project-radar-sector-create'
import { CustomDirectusTypes, Rings, Sectors } from 'types/directus'
import { deleteFile } from './api'

const artificialDelay = process.env.GATSBY_ARTIFICIAL_DELAY || 0

export function* userLogin(payload: IUserLogin) {
  yield delay(artificialDelay)
  yield* call(Api.userLogin, payload)
}

export function* userLogout() {
  return yield* call(Api.userLogout)
}

/* *********************************************************************************************************************
 *  Invitation
 * ********************************************************************************************************************/

export function* invitationStatus(payload: { token: string }) {
  yield delay(artificialDelay)
  const transportResponse = yield* call(Api.invitationStatus, payload)
  if (!transportResponse.data?.status) throw new Error('No status.')
  return transportResponse.data
}

/* *********************************************************************************************************************
 * Registration
 * ********************************************************************************************************************/

export function* userRegistration(payload: IUserRegistration) {
  yield delay(artificialDelay)
  yield* call(Api.userRegistration, payload)
}

export function* userRegistrationConfirmation(payload: string) {
  yield delay(artificialDelay)
  yield* call(Api.userRegistrationConfirmation, payload)
}

export function* userInvitation(payload: { email: string; project_id: string; permission: ProjectPermissions }) {
  yield delay(artificialDelay)
  yield* call(Api.userInvitation, payload)
}

/* *********************************************************************************************************************
 * Password Reset
 * ********************************************************************************************************************/

export function* requestPasswordReset(payload: string) {
  yield delay(artificialDelay)
  yield* call(Api.requestPasswordReset, payload)
}

export function* resetPassword(payload: IResetPassword) {
  yield delay(artificialDelay)
  yield* call(Api.resetPassword, payload)
}

export function* resetPasswordWithToken(payload: IResetPasswordWithToken) {
  yield delay(artificialDelay)
  yield* call(Api.resetPasswordWithToken, payload)
}

/* *********************************************************************************************************************
 * Me
 * ********************************************************************************************************************/

export function* getMe() {
  yield delay(artificialDelay)
  return yield* call(Api.getMe)
}

export function* getUserEmailByToken(token: string) {
  yield delay(artificialDelay)
  const transportResponse = yield* call(Api.getUserEmailByToken, token)
  if (!transportResponse.data?.email) throw new Error('Error in getUserEmailByToken')
  return transportResponse.data
}

export function* updateMe(payload: IUpdateUser) {
  yield delay(artificialDelay)
  const user = yield* call(Api.updateMe, payload)
  yield put(userProfileActions.setPersonalData({ ...user }))
}

export function* deleteMe() {
  yield delay(artificialDelay)
  yield call(Api.deleteMe)
}

/* *********************************************************************************************************************
 * Projects
 * ********************************************************************************************************************/

export function* projectsNew(payload: FormikProps<IFormikCreateProject>) {
  yield delay(artificialDelay)
  return yield* call(Api.createProject, payload)
}

/* *********************************************************************************************************************
 * Rings
 * ********************************************************************************************************************/

export function* ringsCreate({ projectId, data }: { projectId: string; data: Rings[] }) {
  yield delay(artificialDelay)
  return yield* call(Api.ringsCreate, { projectId, data })
}

export function* ringsUpdate({ projectId, data }: { projectId: string; data: Rings[] }) {
  yield delay(artificialDelay)
  return yield* call(Api.ringsUpdate, { projectId, data })
}

export function* ringsDelete({ projectId, data }: { projectId: string; data: string[] }) {
  yield delay(artificialDelay)
  yield* call(Api.ringsDelete, { projectId, data })
}

/* *********************************************************************************************************************
 * Sectors
 * ********************************************************************************************************************/

export function* projectCreateSectors({ projectId, item }: { projectId: string; item: IFormikSectorCreate }) {
  yield delay(artificialDelay)
  return yield* call(Api.projectCreateSectors, { projectId, item })
}

export function* projectUpdateSectors({ sectorId, data }: { sectorId: string; data: Sectors }) {
  yield delay(artificialDelay)
  return yield* call(Api.projectUpdateSectors, { sectorId, data })
}

export function* projectDeleteSectors({ projectId, ids }: { projectId: string; ids: string[] }) {
  yield delay(artificialDelay)
  yield* call(Api.projectDeleteSectors, { projectId, ids })
}

/* *********************************************************************************************************************
 * Blips
 * ********************************************************************************************************************/

export function* projectUpdateBlips({ blipId, data }: { blipId: string; data: CustomDirectusTypes['blips'] }) {
  yield delay(artificialDelay)
  yield* call(Api.projectUpdateBlips, { blipId, data })
}

export function* projectDeleteBlips({ projectId, ids }: { projectId: string; ids: string[] }) {
  yield delay(artificialDelay)
  return yield* call(Api.projectDeleteBlips, { projectId, ids })
}

/* *********************************************************************************************************************
 * Members
 * ********************************************************************************************************************/

export function* projectDeleteMembers({ projectId, ids }: { projectId: string; ids: number[] }) {
  yield delay(artificialDelay)
  return yield* call(Api.projectDeleteMembers, { projectId, ids })
}

/* *********************************************************************************************************************
 * File
 * ********************************************************************************************************************/

export function* uploadFile(payload: { file: File }) {
  yield delay(artificialDelay)
  const transportResponse = yield* call(Api.uploadFile, payload)
  if (!transportResponse.data?.id) throw new Error('Fehler beim Upload der ID')
  return transportResponse.data
}
