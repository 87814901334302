import { Directus } from '@directus/sdk'
import Papa from 'papaparse'
import { DirectusTypes, OneProject } from '../types/responses'
import { Blips, Rings, Sectors } from '../types/directus'

interface IDictionary {
  [id: string]: any
}

type CsvDataRow = {
  blip_id: string
  blip_name: string
  sector_id: string
  sector_name: string
  sector_description: string
  sector_color: string
  sector_text_color: string
  ring_id: string
  ring_name: string
  blip_description: string
  blip_short_description: string
  blip_x: string
  blip_y: string
  blip_halo: string
  blip_type: string
  blip_type_name: string
}

export type CsvImportResult = {
  rings: {
    updated: number
    created: number
    failed: string[]
  }
  sectors: {
    updated: number
    created: number
    failed: string[]
  }
  blips: {
    updated: number
    created: number
    failed: string[]
  }
  types: {
    updated: number
  }
  format: {
    wrongHeaderFieldCount: number
    wrongColumnFieldCount: number
    rowCount: number
  }
  success: boolean
}

/**
 * Importer for CSV data to update project blips, sectors and rings
 */
export class CsvDataImporter {
  protected headerColumns = [
    'blip_id',
    'blip_name',
    'sector_id',
    'sector_name',
    'sector_description',
    'sector_color',
    'sector_text_color',
    'ring_id',
    'ring_name',
    'blip_description',
    'blip_short_description',
    'blip_x',
    'blip_y',
    'blip_halo',
    'blip_type',
    'blip_type_name',
  ]

  protected expectedFieldLength = 16
  protected mappedRings: IDictionary = {}
  protected mappedSectors: IDictionary = {}
  protected importResult: CsvImportResult = {
    rings: {
      updated: 0,
      created: 0,
      failed: [],
    },
    sectors: {
      updated: 0,
      created: 0,
      failed: [],
    },
    blips: {
      updated: 0,
      created: 0,
      failed: [],
    },
    types: {
      updated: 0,
    },
    format: {
      wrongHeaderFieldCount: 0,
      wrongColumnFieldCount: 0,
      rowCount: 0,
    },
    success: false,
  }

  /**
   * Parses the CSV text to return single data rows
   *
   * @param csvData
   */
  public parseCsvIntoRows = (csvData: string) => {
    let headerIndex = 0
    const dataRows: CsvDataRow[] = []

    const rawJsonData = Papa.parse<CsvDataRow>(csvData, {
      header: true,
      delimiter: ';',
      skipEmptyLines: true,
      transformHeader: (header: string) => {
        if (header.includes('Blip-ID')) {
          headerIndex = 0
        }

        return this.headerColumns[headerIndex++]
      },
    })

    if (rawJsonData.meta.fields?.length !== this.expectedFieldLength) {
      this.importResult.format.wrongHeaderFieldCount = 1
      return dataRows
    }

    if (rawJsonData.data.length) {
      rawJsonData.data.forEach((row) => {
        if (Object.keys(row).length !== this.expectedFieldLength) {
          ++this.importResult.format.wrongColumnFieldCount
          this.importResult.blips.failed.push(row.blip_name || row.blip_id)
          return
        }

        if (row.blip_name) {
          dataRows.push(row)
          ++this.importResult.format.rowCount
        } else {
          this.importResult.blips.failed.push(row.blip_id)
        }
      })
    }

    return dataRows
  }

  /**
   * Returns the mapped field name of the given type name of the csv row
   *
   * @param typeNameFromCsv
   */
  protected mapBlipType = (typeNameFromCsv: string) => {
    if (typeNameFromCsv === 'new') {
      return 'blip_triangle_name'
    }

    if (typeNameFromCsv === 'changed') {
      return 'blip_circle_name'
    }

    return 'blip_square_name'
  }

  /**
   * Updates a ring's entity
   *
   * @param directus
   * @param row
   */
  protected updateRing = async (directus: Directus<DirectusTypes>, row: CsvDataRow) => {
    const id = row.ring_id

    // update only, if there is a ring name given, which differs from the already imported one
    // => else leave it to the ring id used by the blips
    if (row.ring_name && this.mappedRings[id].ring_name !== row.ring_name) {
      try {
        await directus.items('rings').updateOne(this.mappedRings[id].ring_id, { name: row.ring_name })
        this.mappedRings[id].ring_name = row.ring_name

        ++this.importResult.rings.updated
      } catch (e) {
        this.importResult.rings.failed.push(id)
      }
    }

    return this.mappedRings[id].ring_id || id
  }

  /**
   * Creates a new ring entity
   *
   * @param directus
   * @param projectId
   * @param row
   */
  protected createRing = async (directus: Directus<DirectusTypes>, projectId: string, row: CsvDataRow) => {
    const newRing: Rings = {
      id: '',
      name: row.ring_name || 'bitte noch benennen',
      project_id: projectId,
    }

    try {
      const insertedRing = await directus.items('rings').createOne(newRing)

      if (insertedRing) {
        const mappedRingId = row.ring_id || insertedRing.id
        this.mappedRings[mappedRingId] = {
          ring_id: insertedRing.id,
          ring_name: insertedRing.name,
        }

        ++this.importResult.rings.created

        return insertedRing.id
      }
    } catch (e) {
      this.importResult.rings.failed.push(row.ring_name)
    }

    return ''
  }

  /**
   * Imports the csv row to handle ring entities
   *
   * @param directus
   * @param project
   * @param row
   */
  protected importRing = async (directus: Directus<DirectusTypes>, project: OneProject, row: CsvDataRow) => {
    // does the ring already exist?
    if (row.ring_id) {
      // it was already mapped from the CSV's ring id to the real one
      if (this.mappedRings[row.ring_id] !== undefined) {
        return await this.updateRing(directus, row)
      } else {
        const existingRings: Rings[] =
          (row.ring_id && project.rings?.filter((ring: Rings) => ring.id === row.ring_id)) || []

        // the ring exists with the given id from the CSV
        if (existingRings.length) {
          // it was found, so add it to the map for later reusing to find differences
          this.mappedRings[row.ring_id] = {
            ring_id: existingRings[0].id,
            ring_name: existingRings[0].name,
          }

          return await this.updateRing(directus, row)
        } else {
          // the ring id does not exist => create a new ring and remap the row's ring id the real one
          return await this.createRing(directus, project.id!, row)
        }
      }
    } else {
      // the ring id does not exist => create a new ring and add the row's ring id the real one
      return await this.createRing(directus, project.id!, row)
    }

    return ''
  }

  /**
   * Updates a sector's entity
   *
   * @param directus
   * @param row
   */
  protected updateSector = async (directus: Directus<DirectusTypes>, row: CsvDataRow) => {
    const id = row.sector_id,
      sectorFields: IDictionary = {}

    // update only, if there is a sector fields given, which differ from the already imported ones
    // => else leave it to the sector id used by the blips

    if (row.sector_name && this.mappedSectors[id].sector_name !== row.sector_name) {
      sectorFields['name'] = row.sector_name
      this.mappedSectors[id].sector_name = row.sector_name
    }

    if (row.sector_description && this.mappedSectors[id].sector_description !== row.sector_description) {
      sectorFields['description'] = row.sector_description
      this.mappedSectors[id].sector_description = row.sector_description
    }

    if (row.sector_color && this.mappedSectors[id].sector_color !== row.sector_color) {
      sectorFields['color'] = row.sector_color
      this.mappedSectors[id].sector_color = row.sector_color
    }

    if (row.sector_text_color && this.mappedSectors[id].sector_text_color !== row.sector_text_color) {
      sectorFields['txt_color'] = row.sector_text_color
      this.mappedSectors[id].sector_text_color = row.sector_text_color
    }

    if (Object.keys(sectorFields).length) {
      try {
        await directus.items('sectors').updateOne(this.mappedSectors[id].sector_id || id, sectorFields)
        ++this.importResult.sectors.updated
      } catch (e) {
        this.importResult.sectors.failed.push(id)
      }
    }

    return this.mappedSectors[id].sector_id || id
  }

  /**
   * Creates a new sector entity
   *
   * @param directus
   * @param project
   * @param row
   */
  protected createSector = async (directus: Directus<DirectusTypes>, project: OneProject, row: CsvDataRow) => {
    const newSector: Sectors = {
      name: row.sector_name || 'bitte noch benennen',
      description: row.sector_description,
      color: row.sector_color,
      txt_color: row.sector_text_color,
      projectId: project.id,
      id: '',
      sort: project.sectors!.length + 1,
    }

    try {
      const insertedSector = await directus.items('sectors').createOne(newSector)

      if (insertedSector) {
        this.mappedSectors[row.sector_id || insertedSector.id] = {
          sector_id: insertedSector.id,
          sector_name: insertedSector.name,
          sector_description: insertedSector.description,
          sector_color: insertedSector.color,
          sector_text_color: insertedSector.txt_color,
        }

        ++this.importResult.sectors.created

        return insertedSector.id
      }
    } catch (e) {
      this.importResult.sectors.failed.push(row.sector_name)
    }

    return ''
  }

  /**
   * Imports the csv row to handle sector entities
   *
   * @param directus
   * @param project
   * @param row
   */
  protected importSector = async (directus: Directus<DirectusTypes>, project: OneProject, row: CsvDataRow) => {
    // does the sector already exist?
    if (row.sector_id) {
      // it was already mapped from the CSV's sector id to the real one
      if (this.mappedSectors[row.sector_id] !== undefined) {
        return await this.updateSector(directus, row)
      } else {
        const existingSectors: Sectors[] =
          (row.sector_id && project.sectors?.filter((sector: Sectors) => sector.id === row.sector_id)) || []

        // the sector exists with the given id from the CSV
        if (existingSectors?.length) {
          // it was found, so add it to the map for later reusing to find differences
          this.mappedSectors[row.sector_id] = {
            sector_id: existingSectors[0].id,
            sector_name: existingSectors[0].name,
            sector_description: existingSectors[0].description,
            sector_color: existingSectors[0].color,
            sector_text_color: existingSectors[0].txt_color,
          }

          return await this.updateSector(directus, row)
        } else {
          // the sector id does not exist => create a new sector and remap the row's sector id the real one
          return await this.createSector(directus, project, row)
        }
      }
    } else {
      // the sector id does not exist => create a new sector and add the row's sector id the real one
      return await this.createSector(directus, project, row)
    }
  }

  /**
   * Updates a blip's entity
   *
   * @param directus
   * @param row
   * @param importedSectorId
   * @param importedRingId
   */
  protected updateBlip = async (
    directus: Directus<DirectusTypes>,
    row: CsvDataRow,
    importedSectorId: string,
    importedRingId: string
  ) => {
    const id = row.blip_id,
      blipFields: IDictionary = {}

    // update only, if there is a sector fields given, which differ from the already imported ones
    // => else leave it to the sector id used by the blips

    if (row.blip_name) {
      blipFields['name'] = row.blip_name
    }

    if (row.blip_description) {
      blipFields['description'] = row.blip_description
    }

    if (row.blip_short_description) {
      blipFields['short_description'] = row.blip_short_description
    }

    if (row.blip_x) {
      blipFields['x'] = row.blip_x
    }

    if (row.blip_y) {
      blipFields['y'] = row.blip_y
    }

    if (row.blip_halo) {
      blipFields['halo'] = row.blip_halo
    }

    if (row.blip_type) {
      blipFields['type'] = row.blip_type
    }

    if (importedSectorId) {
      blipFields['sector'] = importedSectorId
    }

    if (importedRingId) {
      blipFields['ring'] = importedRingId
    }

    if (Object.keys(blipFields).length) {
      try {
        await directus.items('blips').updateOne(id, blipFields)
        ++this.importResult.blips.updated
        this.importResult.success = true
      } catch (e) {
        this.importResult.blips.failed.push(id)
      }
    }
  }

  /**
   * Creates a new blip entity
   *
   * @param directus
   * @param projectId
   * @param row
   * @param importedSectorId
   * @param importedRingId
   */
  protected createBlip = async (
    directus: Directus<DirectusTypes>,
    projectId: string,
    row: CsvDataRow,
    importedSectorId: string,
    importedRingId: string
  ) => {
    const newBlip: Blips = {
      name: row.blip_name,
      description: row.blip_description,
      short_description: row.blip_short_description,
      x: parseFloat(row.blip_x),
      y: parseFloat(row.blip_y),
      halo: parseInt(row.blip_halo),
      type: row.blip_type,
      sector: importedSectorId,
      ring: importedRingId,
    }

    try {
      // @ts-ignore
      await directus.items('projects').updateOne(projectId, { blips: { create: [newBlip] } })
      ++this.importResult.blips.created
      this.importResult.success = true
    } catch (e) {
      this.importResult.blips.failed.push(row.blip_name)
    }
  }

  /**
   * Imports the csv row to handle blip entities
   *
   * @param directus
   * @param project
   * @param row
   * @param importedSectorId
   * @param importedRingId
   */
  protected importBlip = async (
    directus: Directus<DirectusTypes>,
    project: OneProject,
    row: CsvDataRow,
    importedSectorId: string,
    importedRingId: string
  ) => {
    // does the blip already exist?
    if (row.blip_id) {
      const existingBlips = (row.blip_id && project.blips?.filter((blip: Blips) => blip.id === row.blip_id)) || []

      // the blip exists with the given id from the CSV
      if (existingBlips.length) {
        await this.updateBlip(directus, row, importedSectorId, importedRingId)
      } else {
        // the blip id does not exist => create a new blip and remap the row's blip id the real one
        await this.createBlip(directus, project.id!, row, importedSectorId, importedRingId)
      }
    } else {
      // the blip id does not exist => create a new blip and add the row's blip id the real one
      await this.createBlip(directus, project.id!, row, importedSectorId, importedRingId)
    }
  }

  /**
   * Imports a single csv row
   * @param project
   * @param directus
   * @param row
   */
  protected importCsvRow = async (project: OneProject, directus: Directus<DirectusTypes>, row: CsvDataRow) => {
    const projectBlipTypeField = this.mapBlipType(row.blip_type)

    if (row.blip_type_name !== project[projectBlipTypeField]) {
      const updateFields: IDictionary = { [projectBlipTypeField]: row.blip_type_name }
      await directus.items('projects').updateOne(project.id!.toString(), updateFields)
      project[projectBlipTypeField] = row.blip_type_name
      ++this.importResult.types.updated
    }

    const importedRingId = await this.importRing(directus, project, row)
    const importedSectorId = await this.importSector(directus, project, row)

    if (importedRingId && importedSectorId) {
      await this.importBlip(directus, project, row, importedSectorId, importedRingId)
    } else {
      this.importResult.blips.failed.push(row.blip_name)
    }
  }

  /**
   * Imports the CSV data
   *
   * @param project
   * @param directus
   * @param csvData
   */
  public import = async (project: OneProject, directus: Directus<DirectusTypes>, csvData: string) => {
    const rowsToBeImported: CsvDataRow[] = this.parseCsvIntoRows(csvData)

    for (const row of rowsToBeImported) {
      await this.importCsvRow(project, directus, row)
    }

    return this.importResult
  }
}
