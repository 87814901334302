import { createSelector } from '@reduxjs/toolkit'

import { initialState } from '.'
import { RootState } from 'types'

const selectDomain = (state: RootState) =>
  typeof state !== 'undefined' ? state['app/user/profile'] || initialState : initialState

export const selectIsLoading = createSelector([selectDomain], (state) => state.isLoading)

export const selectId = createSelector([selectDomain], (state) => state.id)

export const selectRole = createSelector([selectDomain], (state) => {
  switch (state.role) {
    case '93dd258c-faa7-49fb-9a28-e4be6aa61be2':
      return 'appAdmin'
    case 'da26c6dc-255b-4771-85ee-fb7eb3e1eca5':
      return 'appUser'
    default:
      return undefined
  }
})

export const selectStatus = createSelector([selectDomain], (state) => state.status)

export const selectProcessingStatus = createSelector([selectDomain], (state) => state.processing_status)

export const selectEmail = createSelector([selectDomain], (state) => state.email)

export const selectAvatar = createSelector([selectDomain], (state) => state.avatar)

export const selectFirstName = createSelector([selectDomain], (state) => state.first_name)

export const selectLastName = createSelector([selectDomain], (state) => state.last_name)

export const selectPosition = createSelector([selectDomain], (state) => state.position)

export const selectInstitution = createSelector([selectDomain], (state) => state.institution)

export const selectInfo = createSelector([selectDomain], (state) => state.info)

export const selectBackground = createSelector([selectDomain], (state) => state.background)

export const selectReason = createSelector([selectDomain], (state) => state.reason)
