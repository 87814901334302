exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anleitungen-tsx": () => import("./../../../src/pages/anleitungen.tsx" /* webpackChunkName: "component---src-pages-anleitungen-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-hilfe-support-tsx": () => import("./../../../src/pages/hilfe-support.tsx" /* webpackChunkName: "component---src-pages-hilfe-support-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projekte-tsx": () => import("./../../../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-pages-suche-index-tsx": () => import("./../../../src/pages/suche/index.tsx" /* webpackChunkName: "component---src-pages-suche-index-tsx" */)
}

