import { Directus } from '@directus/sdk'
import {
  IResetPassword,
  IResetPasswordWithToken,
  IUpdateUser,
  IUserLogin,
  IUserObject,
  IUserRegistration,
  ProjectPermissions,
} from './types'
import { FormikProps } from 'formik'
import { IFormik as IFormikCreateProject } from '../components/App/pages/project-create'
import { IFormik as IFormikSectorCreate } from '../components/App/pages/project-radar-sector-create'
import { IDirectusFile } from 'types/directus-file'
import { Blips, Projects, ProjectsDirectusUsers, ProjectsFiles, Rings, Sectors } from 'types/directus'
import { DirectusTypes, ManyProjects, OneProject } from 'types/responses'
import { TSearchResults } from 'components/Search/slice/types'
import { isAppAdmin } from '../utils'
import { CsvDataImporter } from '../importer/csvDataImporter'

export const directus = new Directus<DirectusTypes>(process.env.GATSBY_DIRECTUS || '', { auth: { mode: 'json' } })

/* *********************************************************************************************************************
 * Auth
 * ********************************************************************************************************************/

export const staticLogin = async (token: string) => {
  try {
    await directus.auth.static(token)
  } catch (e) {
    throw e
  }
}

export const getToken = async () => {
  let token = await directus.auth.token

  if (token) {
    try {
      await directus.auth.refreshIfExpired()
    } catch (e) {}
    token = await directus.auth.token
  }

  return token
}

export const userLogin = async ({ email, password }: IUserLogin) => {
  await directus.auth.login({
    email,
    password,
  })
}

export const userLogout = async (): Promise<void> => {
  try {
    await directus.auth.logout()
  } catch (e) {}
}

/* *********************************************************************************************************************
 *  Invitation
 * ********************************************************************************************************************/

export interface IGetInvitationStatusResponse {
  status: 'valid' | 'invalid-no-valid-invitation' | 'valid-existing-user'
}

export const invitationStatus = async ({ token }: { token: string }) => {
  try {
    await directus.auth.refreshIfExpired()
  } catch (e) {}

  return await directus.transport.get<IGetInvitationStatusResponse>(
    `${process.env.GATSBY_DIRECTUS}/apiv1/user/invitation-status/${token}`
  )
}

export const invitationConfirm = async (payload: { token: string }) => {
  await directus.transport.post(`${process.env.GATSBY_DIRECTUS}/apiv1/user/invitation-confirm/`, payload)
}

/* *********************************************************************************************************************
 * Registration
 * ********************************************************************************************************************/

export const userRegistration = async (payload: IUserRegistration) => {
  await directus.transport.post(`${process.env.GATSBY_DIRECTUS}/apiv1/user/registration`, payload)
}

export const userRegistrationConfirmation = async (token: string): Promise<void> => {
  await directus.transport.post(`${process.env.GATSBY_DIRECTUS}/apiv1/user/confirm-registration/${token}`)
}

export const userInvitation = async (payload: {
  email: string
  project_id: string
  permission: ProjectPermissions
}) => {
  await directus.transport.post(`${process.env.GATSBY_DIRECTUS}/apiv1/user/invitation`, payload)
}

/* *********************************************************************************************************************
 * Change Email
 * ********************************************************************************************************************/

export const changeEmail = async (payload: { email: string }) => {
  try {
    await directus.users.me.update(payload)
  } catch (e) {
    throw e
  }
}

/* *********************************************************************************************************************
 * Password Reset
 * ********************************************************************************************************************/

export const requestPasswordReset = async (email: string) => {
  await directus.transport.post(`${process.env.GATSBY_DIRECTUS}/apiv1/user/request-password-reset`, { email })
}

export const resetPassword = async (payload: IResetPassword) => {
  await directus.transport.post(`${process.env.GATSBY_DIRECTUS}/apiv1/user/password-reset`, payload)
}

export const resetPasswordWithToken = async (payload: IResetPasswordWithToken) => {
  await directus.transport.post(`${process.env.GATSBY_DIRECTUS}/apiv1/user/password-reset-with-token`, payload)
}

/* *********************************************************************************************************************
 * Search
 * ********************************************************************************************************************/

export const search = async (payload: { searchString: string }) => {
  return await directus.transport.post<TSearchResults>(`${process.env.GATSBY_DIRECTUS}/apiv1/search`, payload)
}

/* *********************************************************************************************************************
 * Me
 * ********************************************************************************************************************/

export const isLoggedIn = async (): Promise<boolean> => {
  const token = await directus.auth.token
  return !!token
}

export const getMe = async (): Promise<IUserObject> => {
  return (await directus.users.me.read({
    fields: [
      'id',
      'first_name',
      'last_name',
      'email',
      'location',
      'title',
      'description',
      'avatar',
      'status',
      'role',
      'processing_status',
      'position',
      'institution',
      'info',
      'background',
      'reason',
      'profile_is_complete',
    ],
  })) as IUserObject
}

export const updateMe = async (payload: IUpdateUser) => {
  return await directus.users.me.update(payload)
}

export const deleteMe = async (): Promise<void> => {
  await directus.transport.delete(`${process.env.GATSBY_DIRECTUS}/apiv1/user/deletion`)
}

export const getUserEmailByToken = async (token: string) => {
  return directus.transport.get<{ email: string }>(`${process.env.GATSBY_DIRECTUS}/apiv1/user/email/${token}`)
}

export const checkUserProfileIsComplete = async (): Promise<boolean> => {
  const me = await getMe()
  return me.profile_is_complete
}

/* *********************************************************************************************************************
 * Single Project
 * ********************************************************************************************************************/

export const readProjects = async () => {
  // Is user appAdmin?
  const me = await getMe()
  const appAdmin = isAppAdmin(me.role)

  // @ts-ignore
  return (await directus.items('projects').readByQuery({
    fields: [
      '*',
      'user_created.*',
      'rings',
      'sectors.*',
      'blips.*',
      'files.*',
      'members.*',
      'members.directus_users_id.*',
    ],
    filter: {
      ...(!appAdmin && {
        _or: [
          {
            user_created: {
              _eq: me.id,
            },
          },
          {
            members: {
              directus_users_id: {
                _eq: me.id,
              },
            },
          },
        ],
      }),
      status: {
        _neq: 'archived',
      },
    },
  })) as ManyProjects
}

export const createProject = async (payload: FormikProps<IFormikCreateProject>) => {
  await directus.items('projects').createOne(payload.values)
}

export const updateProject = async ({
  projectId,
  data,
}: {
  projectId: string
  data: Omit<Projects, 'halo_1' | 'halo_2' | 'halo_3' | 'halo_4' | 'halo_5' | 'halo_6' | 'halo_7' | 'halo_8' | 'halo_9'>
}) => {
  return await directus.items('projects').updateOne(projectId, data)
}

export const deleteProject = async ({ projectId }: { projectId: string }) => {
  return await directus.items('projects').updateOne(projectId, { status: 'archived', members: [] })
}

/* *********************************************************************************************************************
 * Projects
 * ********************************************************************************************************************/

export const projectRead = async ({ projectId }: { projectId: string }) => {
  // @ts-ignore
  return (await directus.items('projects').readOne(projectId, {
    fields: [
      '*',
      'user_created.*',
      'rings.*',
      'sectors.*',
      'blips.*',
      'files.*',
      'members.*',
      'members.directus_users_id.*',
    ],
  })) as OneProject
}

export const readPublicProjects = async () => {
  return (await directus.items('projects').readByQuery({
    fields: [
      '*',
      'user_created.*',
      'rings',
      'sectors.*',
      'blips.*',
      'files.*',
      'members.*',
      'members.directus_users_id.*',
    ],
    filter: {
      _and: [
        {
          status: {
            _eq: 'published',
          },
        },
        {
          mark_public: {
            _eq: true,
          },
        },
      ],
    },
  })) as ManyProjects
}

/* *********************************************************************************************************************
 * Project-Files
 * ********************************************************************************************************************/

export const filesCreate = async ({ projectId, data }: { projectId: string; data: ProjectsFiles[] }) => {
  await directus.items('projects').updateOne(projectId, {
    // @ts-ignore
    files: { create: data },
  })
}

export const filesDelete = async ({ projectId, ids }: { projectId: string; ids: number[] }) => {
  // @ts-ignore
  return await directus.items('projects').updateOne(projectId, { files: { delete: ids } })
}

/* *********************************************************************************************************************
 * Rings
 * ********************************************************************************************************************/

export const ringsCreate = async ({ projectId, data }: { projectId: string; data: Rings[] }) => {
  // @ts-ignore
  await directus.items('projects').updateOne(projectId, { rings: { create: data } })
}

export const ringsUpdate = async ({ projectId, data }: { projectId: string; data: Rings[] }) => {
  await directus.items('projects').updateOne(projectId, {
    // @ts-ignore
    rings: { update: data },
  })
}

export const ringsDelete = async ({ projectId, data }: { projectId: string; data: string[] }) => {
  // @ts-ignore
  await directus.items('projects').updateOne(projectId, { rings: { create: [], update: [], delete: data } })
}

/* *********************************************************************************************************************
 * Sectors
 * ********************************************************************************************************************/

export const projectCreateSectors = async ({ projectId, item }: { projectId: string; item: IFormikSectorCreate }) => {
  // @ts-ignore
  return await directus.items('projects').updateOne(projectId, { sectors: { create: [item] } })
}

export const projectUpdateSectors = async ({ sectorId, data }: { sectorId: string; data: Sectors }) => {
  return await directus.items('sectors').updateOne(sectorId, data)
}

export const projectDeleteSectors = async ({ projectId, ids }: { projectId: string; ids: string[] }) => {
  // @ts-ignore
  await directus.items('projects').updateOne(projectId, { sectors: { delete: ids } })
}

/* *********************************************************************************************************************
 * Blips
 * ********************************************************************************************************************/

export const projectCreateBlips = async ({ projectId, data }: { projectId: string; data: Blips }) => {
  // @ts-ignore
  return await directus.items('projects').updateOne(projectId, { blips: { create: [data] } })
}

export const projectUpdateBlips = async ({ blipId, data }: { blipId: string; data: Blips }) => {
  await directus.items('blips').updateOne(blipId, data)
}

export const projectDeleteBlips = async ({ projectId, ids }: { projectId: string; ids: Array<string> }) => {
  // @ts-ignore
  return await directus.items('projects').updateOne(projectId, { blips: { delete: ids } })
}

/* *********************************************************************************************************************
 * Members
 * ********************************************************************************************************************/

export const changeMemberPermissions = async ({
  projectId,
  member,
}: {
  projectId: string
  member: ProjectsDirectusUsers
}) => {
  // @ts-ignore
  return await directus.items('projects').updateOne(projectId, { members: { update: [member] } })
}

export const projectDeleteMembers = async ({ projectId, ids }: { projectId: string; ids: number[] }) => {
  // @ts-ignore
  return await directus.items('projects').updateOne(projectId, { members: { delete: ids } })
}

/* *********************************************************************************************************************
 * File
 * ********************************************************************************************************************/

export const uploadFile = async ({ file }: { file: File }) => {
  const form = new FormData()
  form.append('file', file)
  return await directus.transport.post<IDirectusFile>(`${process.env.GATSBY_DIRECTUS}/files`, form)
}

export const deleteFile = async ({ fileId }: { fileId: string }) => {
  return await directus.transport.delete(`${process.env.GATSBY_DIRECTUS}/files/${fileId}`)
}

/* *********************************************************************************************************************
 * Export
 * ********************************************************************************************************************/
export const exportCsv = async ({ projectId }: { projectId: string }) => {
  return directus.transport.get(`${process.env.GATSBY_DIRECTUS}/apiv1/export/csv/${projectId}`)
}

export const exportPdf = async ({ projectId, blips }: { projectId: string; blips: string }) => {
  let url = `${process.env.GATSBY_DIRECTUS}/apiv1/export/pdf/${projectId}`

  if (blips) {
    url += `/blips/${blips}`
  }

  return directus.transport.get(url)
}

export const exportPptx = async ({ projectId, blips }: { projectId: string; blips: string }) => {
  let url = `${process.env.GATSBY_DIRECTUS}/apiv1/export/pptx/${projectId}`

  if (blips) {
    url += `/blips/${blips}`
  }

  return directus.transport.get(url)
}

export const exportStatistics = async () => {
  return directus.transport.get(`${process.env.GATSBY_DIRECTUS}/apiv1/statistics`)
}

/* *********************************************************************************************************************
 * Import
 * ********************************************************************************************************************/

export const importCsvData = async ({ projectId, csvData }: { projectId: string; csvData: string }) => {
  const me = await getMe()
  const appAdmin = isAppAdmin(me.role)

  const project: Projects = (await directus.items('projects').readOne(projectId, {
    fields: ['*', 'rings.*', 'sectors.*', 'blips.*'],
    filter: {
      ...(!appAdmin && {
        _or: [
          {
            user_created: {
              _eq: me.id,
            },
          },
          {
            members: {
              directus_users_id: {
                _eq: me.id,
              },
            },
          },
        ],
      }),
      status: {
        _neq: 'archived',
      },
    },
  })) as OneProject

  let importResult

  if (project) {
    const csvImporter = new CsvDataImporter()
    importResult = await csvImporter.import(project, directus, csvData)
  }

  return importResult
}
