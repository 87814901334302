import { createSlice } from 'utils/@reduxjs/toolkit'
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors'
import { IInitialState } from './types'
import { saga } from './saga'

export const initialState: IInitialState = {
  isInitialized: false,
  isLoggedIn: false,
  userProfileIsComplete: false,
  showInnoRadarAnimation: true,
}

export type TRequestPayload = boolean

type TResponse = {
  type: string
  payload: {
    isInitialized: boolean
    isLoggedIn: boolean
    userProfileIsComplete: boolean
  }
}

interface ISet {
  type: string
  payload: {
    showInnoRadarAnimation: boolean
  }
}

export interface IInit {
  type: string
  payload: {
    token: string | null
  }
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    set(state, { payload }: ISet) {
      typeof payload.showInnoRadarAnimation !== 'undefined' &&
        (state.showInnoRadarAnimation = payload.showInnoRadarAnimation)
    },
    init(state, { payload }: IInit) {
      payload && (state.isInitialized = false)
    },
    response(state, { payload }: TResponse) {
      state.isInitialized = payload.isInitialized
      state.isLoggedIn = payload.isLoggedIn
      state.userProfileIsComplete = payload.userProfileIsComplete
    },
    setIsLoggedIn(state, { payload }: { payload: TRequestPayload }) {
      state.isLoggedIn = payload
    },
    setUserProfileIsComplete(state, { payload }: { payload: { userProfileIsComplete: boolean } }) {
      state.userProfileIsComplete = payload.userProfileIsComplete
    },
    reset() {},
  },
})

export const { name: key, actions, reducer } = slice

export const useAppSlice = () => {
  useInjectReducer({ key, reducer })
  useInjectSaga({ key, saga })
  return { appActions: actions }
}
