import { useRive } from '@rive-app/react-canvas'
import * as React from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { selectPosition } from './slice/selectors'

const animationSpeed = 0.5

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: animationSpeed, duration: animationSpeed } },
}
export const InnoRadarAnimation = () => {
  // Selectors.
  const position = useSelector(selectPosition)

  // Hooks.
  const { RiveComponent } = useRive(
    {
      src: '../../../../images/animations/anmeldung.riv',
      autoplay: true,
    },
    { fitCanvasToArtboardHeight: true }
  )

  return (
    <div className='absolute inset-0 pointer-events-none'>
      <div className='container mx-auto lg:min-h-screen flex flex-col relative'>
        <div className='lg:absolute z-10 flex flex-col justify-center h-full mt-16 lg:mt-0'>
          <div className='h-[67px]' />
        </div>
        <div className='grid grid-cols-12 gap-4 grow lg:relative'>
          <div className='col-span-full lg:col-span-8 pl-4 flex flex-col justify-center lg:min-h-[530px]'>
            <div
              className={classNames(
                'hidden lg:block relative transition-all delay-150 duration-1000',
                position === 'outOfView' ? 'left-[-9999px]' : position === 'left' ? 'left-[-600px]' : 'left-0'
              )}
            >
              <motion.div initial='initial' animate='animate' variants={pageVariants}>
                <RiveComponent />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
