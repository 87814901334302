import { createSlice } from 'utils/@reduxjs/toolkit'
import { useInjectReducer } from 'utils/redux-injectors'
import { IInitialState } from './types'

export const initialState: IInitialState = {
  position: 'left',
}

export interface ISet {
  type: string
  payload: {
    position: 'left' | 'centered' | 'outOfView'
  }
}

const slice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    setPosition(state, { payload }: ISet) {
      typeof payload.position !== 'undefined' && (state.position = payload.position)
    },
  },
})

export const { name: key, actions, reducer } = slice

export const useAnimationSlice = () => {
  useInjectReducer({ key, reducer })
  return { animationActions: actions }
}
