import * as React from 'react'
import { InnoRadarAnimation } from 'components/InnoRadarAnimation'
import { useAppSlice } from 'components/App/slice'
import { useSlice as useUserProfileSlice } from 'components/App/pages/user-profile/slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsInitialized } from 'components/App/slice/selectors'
import { useLocation } from '@reach/router'

interface IProps {
  children: React.ReactElement
}

export const StaticLayout = ({ children }: IProps) => {
  // State.
  const [isClient, setClient] = React.useState(false)
  // https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/

  // Effects.
  React.useEffect(() => {
    setClient(true)
  }, [])

  // Hooks.
  const { appActions } = useAppSlice()
  const location = useLocation()
  useUserProfileSlice()

  // Selectors.
  const isInitialized = useSelector(selectIsInitialized)

  // Hooks.
  const dispatch = useDispatch()

  // Effects.
  React.useEffect(() => {
    // Parse token from URL.
    const token = new URLSearchParams(location.search).get('token')

    if (!isInitialized) {
      dispatch(appActions.init({ token }))
    }
  }, [appActions, dispatch, isInitialized, location])

  return (
    <>
      {isClient && (
        <>
          <div className='bg-bg-primary fixed h-full w-full -z-10' />
          <div>
            <InnoRadarAnimation />
            {children}
          </div>
        </>
      )}
    </>
  )
}
